import Headder from "./componente/headder";
import "./App.css";
import Footer from "./componente/footer";
import { createMuiTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Box from "./componente/carousel";

const theme = createMuiTheme({
  typography: {
    fontFamily: [

      'Alloy-Regular'
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Headder/>
      <div style={{background: "#043d35",display:"flex", justifyContent: "center", alignItems: "center"}}>
      <Box/>
      </div>
      <Footer/>
    </div>
    </ThemeProvider>
  );
}

export default App;
import './headder.css'
import swlogodark from "../imagini/swlogo.png"


const Headder = () => {
  return (
    <div>
      
    <div className="App-header">
      <img class="img2" src={swlogodark} className="App-logo" alt="logo" />
      <div>
      <h1 style={{margin: "1px"}} className="Menu-title">LINEUP</h1>
      <h1 style={{margin: "1px", textAlign: "center" }} className="Menu-title"><span style={{color:"white"}}>SW </span>31</h1>
      </div>
    </div>
    </div>
  );
};

export default Headder;